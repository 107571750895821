













































import { Component, Vue } from 'vue-property-decorator';

import { ref } from '@vue/composition-api';
import BattletrisIcon from '../icons/battletris.vue';
import Tooltip from '../components/Tooltip.vue';
import QuickLinks from '../components/QuickLinks.vue';
import { getRequest } from '../lib/request';

@Component({
  components: {
    BattletrisIcon,
    QuickLinks,
    Tooltip,
  },
  setup() {
    const loading = ref(true);
    const rooms = ref<any[] | null>(null);

    (async () => {
      rooms.value = await getRequest('rooms');
      loading.value = false;
    })();

    return {
      loading,
      rooms,
    };
  },
})
export default class Tutorial extends Vue {}
